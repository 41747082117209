import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

/// TODO: Define props for NotFound
const NotFound = (props) => {
  return (
    <div data-testid={props.testID} className={`NotFound ${props.size} more utility classes`}>
      <h1>404</h1>
      <h2>
        Sorry, deze pagina bestaat niet.
        <br />
        Ga terug naar <Link to={'/'}>home</Link>
      </h2>
    </div>
  )
}

NotFound.propTypes = {
  testID: PropTypes.string
}

export default NotFound
