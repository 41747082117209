import PropTypes from 'prop-types'
import MarkdownView from 'react-showdown'
import { Row, Col } from 'react-bootstrap'

const Paragraph = ({ testID, type = 'regular', text, color = '#000', weight = 'normal', position, podcast = false, form = false }) => {
  const renderPodcast = () => {
    if (podcast) {
      return (
        <iframe
          style={{ border: 'none' }}
          title='Einde Discussie!'
          src='https://www.podbean.com/media/player/multi?playlist=http%3A%2F%2Fplaylist.podbean.com%2F9929874%2Fplaylist_multi.xml&amp;vjs=1&amp;size=430&amp;skin=1&amp;episode_list_bg=%23ffffff&amp;bg_left=%23000000&amp;bg_mid=%230c5056&amp;bg_right=%232a1844&amp;podcast_title_color=%23c4c4c4&amp;episode_title_color=%23ffffff&amp;auto=0&amp;share=1&amp;fonts=Helvetica&amp;download=1&amp;rtl=0&amp;show_playlist_recent_number=10'
          scrolling='no'
          width='100%'
          height='430'
        ></iframe>
      )
    }
  }

  const renderForm = () => {
    if (form) {
      return <div></div>
    }
  }

  return (
    <>
      {/*REGULAR*/}
      <div data-testid={testID} data-object-type={type ?? ''} data-object-color={color ?? '#000'} className={`Paragraph`}>
        <Row>
          {position === 'right' && <Col md={5} />}
          <Col sm={12} xs={12} md={7}>
            <MarkdownView
              markdown={text}
              options={{
                tables: true,
                emoji: true
              }}
            />
            {renderPodcast()}
            {renderForm()}
          </Col>
          {position === 'left' && <Col md={5} />}
        </Row>
      </div>

      {/* MOBILE */}
      <div data-testid={testID} data-object-type={type ?? ''} data-object-color={color ?? '#000'} className={`MobileParagraph`}>
        <Row>
          <Col md={12}>
            <MarkdownView
              markdown={text}
              options={{
                tables: true,
                emoji: true
              }}
            />
            {renderPodcast()}
            {renderForm()}
          </Col>
        </Row>
      </div>
    </>
  )
}

const types = ['colorized', 'regular']
const colors = ['blue', 'pink', 'black']
const weights = ['normal', 'bold']

Paragraph.propTypes = {
  testID: PropTypes.string,
  type: PropTypes.oneOf(types),
  color: PropTypes.oneOf(colors),
  weight: PropTypes.oneOf(weights),
  text: PropTypes.string
}

export default Paragraph
