import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import { Paragraph } from '../../atoms'

const MainPage = ({ testID, type, data }) => {
  const [content, setContent] = useState('')
  useEffect(() => {
    fetch(data.content)
      .then((response) => response.text())
      .then((text) => setContent(text))
  }, [data])

  const renderImage = (isMobile = false) => {
    const style = {
      backgroundImage: `url(${data.img})`
    }
    const clsm = `${isMobile ? 'mobile-image' : 'page-image'}`
    return <Col md={6} className={clsm} data-object-type={data.color} style={style}></Col>
  }

  return (
    <Container fluid data-testid={testID} data-object-type={type ?? ''} className={`MainPage`}>
      <Row>
        {type === 'left' && renderImage(false)}
        {renderImage(true)}
        <Col sm={12} xs={12} md={6}>
          <Paragraph text={content} position={type} color={data.color} podcast={data.type === 'podcast'} form={false} />
        </Col>
        {type === 'right' && renderImage()}
      </Row>
    </Container>
  )
}

const exceptionClasses = ['left', 'right']

MainPage.propTypes = {
  testID: PropTypes.string,
  type: PropTypes.oneOf(exceptionClasses)
}

export default MainPage
