import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Outlet, Link } from 'react-router-dom'
import { Logo } from '../../atoms'
import { Row, Col } from 'react-bootstrap'
import Hamburger from 'hamburger-react'

const Navigation = ({ testID }) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <div data-testid={testID} className={`Navigation`}>
      <Row>
        <Col md={2}>
          <Logo />
        </Col>
        <Col md={10} className={'navigator'}>
          <nav>
            <ul>
              <li>
                <a href='/'>Home</a>
              </li>
              <li>
                <a href='/majella'>Majella</a>
              </li>
              <li>
                <a href='/spreken'>Spreken</a>
              </li>
              <li>
                <a href='/luisteren'>Luisteren</a>
              </li>
              <li>
                <a href='/podcast'>Podcast</a>
              </li>
              <li>
                <a href='/contact'>Contact</a>
              </li>
            </ul>
          </nav>
        </Col>
        <Col md={10} className={'mobile-navigator'}>
          <div className={'burger'}>
            <Hamburger size={40} toggled={isOpen} toggle={setOpen} />
          </div>
          {isOpen && (
            <div className={'mobile-menu'}>
              <nav>
                <ul>
                  <li>
                    <a href='/'>Home</a>
                  </li>
                  <li>
                    <a href='/majella'>Majella</a>
                  </li>
                  <li>
                    <a href='/spreken'>Spreken</a>
                  </li>
                  <li>
                    <a href='/luisteren'>Luisteren</a>
                  </li>
                  <li>
                    <a href='/podcast'>Podcast</a>
                  </li>
                  <li>
                    <a href='/contact'>Contact</a>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Outlet />
      </Row>
    </div>
  )
}

Navigation.propTypes = {
  testID: PropTypes.string
}

export default Navigation
