import PropTypes from 'prop-types'
import LogoPink from '../../../resources/images/logo-magenta.png'
import LogoBlue from '../../../resources/images/logo-cyaan.png'

const Logo = ({ testID, type }) => {
  const logo = type === 'blue' ? LogoBlue : LogoPink
  return (
    <div data-testid={testID} data-object-type={type ?? ''} className={`Logo`}>
      <img src={logo} alt={'all about talking'} />
    </div>
  )
}

const types = ['pink', 'blue']

Logo.propTypes = {
  type: PropTypes.oneOf(types)
}

export default Logo
