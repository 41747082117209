import home from './home.md'
import majella from './majella.md'
import spreken from './spreken.md'
import luisteren from './luisteren.md'
import contact from './contact.md'
import podcast from './podcast.md'
import voorwaarden from './algemene-voorwaarden.md'
import privacy from './privacy.md'

export const data = [
  { id: 1, color: 'pink', type: 'home', img: require('../resources/images/majella-spreekt-magenta.png'), content: home },
  { id: 2, color: 'pink', type: 'majella', img: require('../resources/images/majella-lammers-v2.jpg'), content: majella },
  { id: 3, color: 'pink', type: 'spreken', img: require('../resources/images/majella-spreekt-magenta.png'), content: spreken },
  { id: 4, color: 'blue', type: 'luisteren', img: require('../resources/images/majella-luistert-cyaan.png'), content: luisteren },
  { id: 5, color: 'pink', type: 'podcast', img: require('../resources/images/majella-podcast.jpg'), content: podcast },
  { id: 6, color: 'blue', type: 'contact', img: require('../resources/images/wake-up-move-cyaan.png'), content: contact },
  { id: 6, color: 'pink', type: 'algemene-voorwaarden', img: require('../resources/images/wake-up-move-magenta.png'), content: voorwaarden },
  { id: 6, color: 'blue', type: 'privacy', img: require('../resources/images/wake-up-move-cyaan.png'), content: privacy }
]
