import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { MainPage, NotFound } from './components/pages'
import { Navigation } from './components/templates'
import { data } from './data'

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigation />}>
          <Route index element={<MainPage type={'left'} data={data.find((page) => page.type === 'home')} />} />
          <Route path={'/majella'} element={<MainPage type={'right'} data={data.find((page) => page.type === 'majella')} />} />
          <Route path={'/spreken'} element={<MainPage type={'left'} data={data.find((page) => page.type === 'spreken')} />} />
          <Route path={'/luisteren'} element={<MainPage type={'left'} data={data.find((page) => page.type === 'luisteren')} />} />
          <Route path={'/podcast'} element={<MainPage type={'left'} data={data.find((page) => page.type === 'podcast')} />} />
          <Route path={'/contact'} element={<MainPage type={'left'} data={data.find((page) => page.type === 'contact')} />} />
          <Route path={'/algemene-voorwaarden'} element={<MainPage type={'left'} data={data.find((page) => page.type === 'algemene-voorwaarden')} />} />
          <Route path={'/privacy'} element={<MainPage type={'left'} data={data.find((page) => page.type === 'privacy')} />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
